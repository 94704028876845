
import { IonApp, IonRouterOutlet, IonPage, IonContent, /*IonGrid, IonRow, IonCol*/ } from '@ionic/vue';
import { defineComponent } from 'vue';
import Header from './views/components/Header.vue';
import MobileHeader from './views/components/MobileHeader.vue';

export default defineComponent({
    name: 'App',
    components: {
        IonApp, IonRouterOutlet, IonPage, IonContent, //IonGrid, IonCol, IonRow,
        Header, MobileHeader
    },
    mounted() {
        window.onclick = (event: any) => {
          if (!event.target.matches('.profile') && !event.target.matches('dropdown-content') ) {
              const dropdowns = document.getElementsByClassName("dropdown-content");
              for (let i = 0; i < dropdowns.length; i++) {
                  const openDropdown = dropdowns[i];
                  if (openDropdown.classList.contains('show')) {
                      openDropdown.classList.remove('show');
                  }
              }
          }
        }
    }
});
