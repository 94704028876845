<template>
    <div style="display: grid; justify-items: center;">
        <img class="profile-big mt-2" v-if="errorImgLoading" @click="openDetail()" src="@/../public/assets/profile.png" alt="" style="width: 90px"/>
        <img class="profile-big mt-2" v-else @error="errorImgLoading = true" @click="openDetail()" :src="profileUrl" style="width: 90px"/>
        <b>{{ userDetail?.user_data?.username }}</b>
        <div class="mt-2">
            <table class="text-style">
            <tr>
                <td class="table-key">Date of Birth:</td>
                <td class="table-value">{{ userDetail?.date_birth }}</td>
            </tr>
            <tr>
                <td class="table-key">Gender:</td>
                <td class="table-value">{{ userDetail?.gender }}</td>
            </tr>
            <tr style="vertical-align: baseline">
                <td class="table-key" v-if="ownRoles.length > 1">Roles:</td>
                <td class="table-key" v-else>Role:</td>
                <td class="table-value">
                <div v-for="r of ownRoles" :key="r">{{ r }}</div>
                </td>
            </tr>
            <tr style="vertical-align: baseline">
                <td class="table-key" v-if="ownLocations.length > 1">Hubs:</td>
                <td class="table-key" v-else>Hub:</td>
                <td class="table-value">
                    <div v-for="loc of ownLocations" :key="loc">{{ loc.name }}</div>
                </td>
            </tr>
            <tr>
                <td class="table-key">Phone:</td>
                <td class="table-value">{{ userDetail?.telephone_number }}</td>
            </tr>
            <tr>
                <td class="table-key">Address:</td>
                <td class="table-value">{{ userDetail?.address }}</td>
            </tr>
            </table>
        </div>
        <div class="extra-detail mt-2">
            <div class="flex" @click="openSyncPage()">
                <b class="clickable">Synchronize Data &nbsp;</b>
                <b v-if="syncData > 0" class="clickable alert-text">({{ syncData }})</b>
            </div>
            <b class="mt-2 clickable" @click="logout()">LOGOUT</b>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from "@vue/runtime-core";
import config from '@/../public/assets/conf.json';

export default defineComponent({
    name: "User Detail",
    data() {
        return {
            errorImgLoading:false,
            syncData: 0
        }
    },
    mounted() {
        setInterval(() => {
            const syncData = localStorage.getItem('cache:wma:records')
            if (syncData) {
                this.syncData = JSON.parse(syncData).filter((el: any) => el.status == 'pending').length
            } else {
                this.syncData = 0
            }
        }, 1000)
    },
    computed: {
        profileUrl() {
            return ''.concat(config['host_ip'], config['host_port'], this.$store.state.userDetail?.user_img)
        },
        userDetail() {
            return this.$store.state.userDetail
        },
        logoutResult() {
            return this.$store.state.accessResult
        },
        ownRoles() {
            const roles = [] as any[]
            if(this.$store.state.userDetail?.user_data?.groups) {
                return this.$store.state.userDetail?.user_data?.groups.filter((el: any) => { return el != 'Subscriber'})
            }
            return roles
        },
        ownLocations() {
            const locations = [] as any[]
            if(this.$store.state.userDetail?.hubs) {
                return this.$store.state.userDetail?.hubs
            }
            return locations
        }
    },
    methods: {
        logout() {
            this.$store.commit("removeToken")
        },
        openSyncPage() {
            const dropdowns = document.getElementsByClassName("dropdown-content");
            for (let i = 0; i < dropdowns.length; i++) {
                const openDropdown = dropdowns[i];
                if (openDropdown.classList.contains('show')) {
                    openDropdown.classList.remove('show');
                }
            }
            this.$router.push('/synchronization')
        }
    },
    watch: {
        logoutResult: {
            handler: function(newVal) {
                if (newVal === '-1') {
                    this.$router.push('/')
                }
            }
        }
    }
})
</script>

<style scoped>
.profile-big {
  border-radius: 50%;
  border: 3px solid #a9d8f7;
  margin-bottom: 5px;
}

.text-style {
  text-align: start;
  font-size: 12px;
}

.table-value {
    padding-left: 10px;
    font-weight: bold;
    max-width: 130px;
}

.extra-detail {
    padding-bottom: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.uname {
  font-size: 12px;
  color: darkslategray;
}

.alert-text {
    color: red;
}
</style>