import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';
import axios from 'axios';
import store from '../store';
import config from '@/../public/assets/conf.json';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: () => import('@/views/Home.vue'),
    meta: { requiresAuth: false }
  },
  {
    path: '/signup',
    name: 'Self Registration',
    component: () => import('@/views/SignUp.vue'),
    meta: { requiresAuth: false }
  },
  {
    path: '/hub',
    name: 'Your Hub',
    component: () => import('@/views/YourHub.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/locations',
    name: 'Locations',
    component: () => import('@/views/Locations.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/operations/:op?',
    name: 'Operations',
    component: () => import('@/views/Operations.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: () => import('@/views/Dashboard.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/synchronization',
    name: 'Synchronization',
    component: () => import('@/views/Synchronizer.vue'),
    meta: { requiresAuth: true }
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
    
  const tkn = store.state.jwt
  if (tkn) {
    // check authorization headers
    if (!axios.defaults.headers.common['Authorization']) {
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + tkn;
    }

    // check userDetail in store 
    if (store.state.userDetail &&  Object.keys(store.state.userDetail).length === 0) {
      axios.get(''.concat(config["host_ip"], config["host_port"], '/user-detail'))
      .then((response) => {
        store.state.userDetail = response.data
        axios.get(''.concat(config["host_ip"], config["host_port"], '/own_location'))
        .then((response) => {
          // if user has no role
          if (response.data.length == 0) {
              store.commit("removeToken")
              next('/')
              return
          }
          store.state.userDetail['hubs'] = response.data
          localStorage.setItem('p4g_userInfo', JSON.stringify(store.state.userDetail))
          })
        .catch((error) => {
          console.log(error);
        })
      })
    }

    if (store.state.userDetail && !('hubs' in store.state.userDetail)) {
      axios.get(''.concat(config["host_ip"], config["host_port"], '/own_location'))
        .then((response) => {
          // if user has no role
          if (response.data.length == 0) {
              store.commit("removeToken")
              next('/')
              return
          }
          store.state.userDetail['hubs'] = response.data
          localStorage.setItem('p4g_userInfo', JSON.stringify(store.state.userDetail))
          })
        .catch((error) => {
          console.log(error);
        })
    }
    
    // inspect tkn
    store.dispatch('inspectToken').then( (statusOk: any) => {

      if (!statusOk) {
        next('/')
        return
      } else {
        // if logged in and try to access login page, redirect
        if (to.path == '/') {
          next('/hub')
          return
        }
  
        next()
      }
    }).catch(err => console.log(err))
  } else {
    // if tkn undefined and user try to navigate, redirect to login
    
    if (to.meta.requiresAuth) {
      next('/')
    } else {
      next()
    }
    return
  }
})


export default router