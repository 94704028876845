import { createApp } from 'vue'
import App from './App.vue'
import router from './router';

import { IonicVue } from '@ionic/vue';
import store from './store';

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

/* Theme variables */
import './theme/variables.css';
import './theme/custom.css';

/* Icons Import */
import { addIcons } from "ionicons";
import { eyeOffOutline, eyeOutline, arrowBackOutline, calendarOutline, arrowUpOutline, arrowDownOutline, chevronBackOutline, chevronForwardOutline, searchOutline, closeOutline, addOutline, caretDownOutline, menuOutline } from "ionicons/icons";

/* Add Ionic Icons here */
addIcons({
  "visible": eyeOutline,
  "not-visible": eyeOffOutline,
  "back-arrow": arrowBackOutline,
  "calendar": calendarOutline,
  "up": arrowUpOutline,
  "down": arrowDownOutline,
  "ch-forward": chevronForwardOutline,
  "ch-back": chevronBackOutline,
  "search": searchOutline,
  "close": closeOutline,
  "add": addOutline,
  "down-arrow": caretDownOutline,
  "menu": menuOutline,
  });

import VCalendar from 'v-calendar';
import VueApexCharts from "vue3-apexcharts";
import "./registerServiceWorker.ts";

const app = createApp(App)
  .use(IonicVue)
  .use(router)
  .use(store)
  .use(VCalendar)
  .use(VueApexCharts);
  
router.isReady().then(() => {
  app.mount('#app');
});