/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/camelcase */

import Vuex from 'vuex'
import axios from 'axios';
import config from '@/../public/assets/conf.json';
import jwt_decode from 'jwt-decode';
import * as Cookies from 'js-cookie';


// Create a new store instance.
const store = new Vuex.Store({
  state: {
    jwt: Cookies.getJSON('p4g_token') || '',
    endpoints: {
        obtainJWT: ''.concat(config["host_ip"], config["host_port"], '/auth/obtain_token/'),
        refreshJWT: ''.concat(config["host_ip"], config["host_port"], '/auth/refresh_token/')
    },
    accessResult: Cookies.getJSON('p4g_token') ? '200' : '-1',
    tokenChecker: '',
    userDetail: JSON.parse(localStorage.getItem('p4g_userInfo') || '{}')
  },
  mutations: { 
    updateToken(state, data) {
      Cookies.set('p4g_token', data.data.access, {expires: config["cookie_expire"]})
      state.jwt = data.data.access
      //state.accessResult = data.status
      state.tokenChecker = 'ok'

      if (!axios.defaults.headers.common['Authorization']) {
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + data.data.access;
      }

      axios.get(''.concat(config["host_ip"], config["host_port"], '/user-detail'))
      .then((response) => {
        state.userDetail = response.data
        axios.get(''.concat(config["host_ip"], config["host_port"], '/own_location/'))
        .then((response) => {
          // if user has no role
          if (response.data.length == 0) {
              store.commit("removeToken")
              state.accessResult = '-2'
              return
          } else {
              state.accessResult = data.status
          }
          state.userDetail['hubs'] = response.data
          localStorage.setItem('p4g_userInfo', JSON.stringify(state.userDetail))
          })
        .catch((error) => {
          console.log(error);
        })
      })
    },
    removeToken(state) {
      Cookies.remove('p4g_token')
      localStorage.removeItem('p4g_userInfo')
      state.jwt = ''
      state.userDetail = null
      state.accessResult = '-1'
      axios.defaults.headers.common['Authorization'] = '';
    }
  },
  actions: {
    obtainToken({state}, payload) {
        axios.post(state.endpoints.obtainJWT, payload)
        .then((response) => {
          this.commit('updateToken', response);
          })
        .catch((error) => {
          console.log(error);
          state.accessResult = error.response.status;
        })
      },
      refreshToken({state}) {
        const payload = {
          token: state.jwt
        }
        axios.post(state.endpoints.refreshJWT, payload)
          .then((response) => {
            this.commit('updateToken', response)
            })
          .catch((error) => {
              console.log(error)
            })
      },
      inspectToken({ state }) {
        const token = state.jwt;
        if (token) {
          if (token == 'undefined') {
            state.tokenChecker = 'Errore nel controllo della sessione! Per favore, effettua l\'accesso.'
            this.commit('removeToken');
            return false
          }
          const decoded = jwt_decode(token);
          const exp = decoded.exp * 1000
          if (Date.now() > exp) {
            //this.dispatch('refreshToken')
            state.tokenChecker = 'Sessione scaduta! Per favore, effettua l\'accesso.'
            this.commit('removeToken');
            return false
          } else {
            state.tokenChecker = 'ok'
            return true
          }
        }
      }
    }
})

export default store
