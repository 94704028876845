<template>
    <div class="navbar">

      <div style="display: flex; align-items: center;">
        <div class="title">{{ appName }}</div>
        <div class="inline-flex" v-if="isLogged">
          <img class="icon-status ml-2" v-if="isOnline" src="@/../public/assets/sync.svg"/>
          <img class="icon-status ml-2" v-else src="@/../public/assets/offline.svg"/>
          <div class="badge2">{{ isOnline ? 'online' : 'offline' }}</div>
        </div>
      </div>

      <div class="menu" v-if="isLogged">
        <b class="entry mr-2 clickable" v-for="entry of menuEntries" :key="entry" v-bind:class="[isActive(entry.link) ? 'active' : '']" @click="openSection(entry)">{{ entry.name }}</b>

        <div style="position: relative">
          <img class="profile mr-2 clickable" v-if="errorImgLoading" @click="openDetail()" src="@/../public/assets/profile.png" alt=""/>
          <img class="profile mr-2 clickable" v-else @error="errorImgLoading = true" @click="openDetail()" :src="profileUrl"/>
          <div v-if="syncStatus > 0" class="badge"></div>
        </div>


        <div id="detail" class="dropdown-content">
          <user-detail></user-detail>
        </div>
      </div>
    </div>

    <div class="temp-card" v-if="openOptions">
      <div class="options-table cell-border" v-for="opt of permittedOptions" :key="opt">
        <label class="clickable" @click="openOperation(opt.link)">{{ opt.name }}</label>
      </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import config from '@/../public/assets/conf.json';
import UserDetail from './UserDetail.vue';

export default defineComponent({
  name: 'Home',
  components: {
    UserDetail
  },
  data() {
      return {
          appName: config['app_name'],
          menuEntries: [
            {name: 'Your Hub', link: '/hub'},
            {name: 'Locations', link: '/locations'},
            {name: 'Operations', link: '/operations', showOptions: true},
            {name: 'Dashboard', link: '/dashboard'},
          ],
          options: [
            {name: 'Register Waste Delivery - In', link: '/operations/1'},
            {name: 'Register Waste Delivery - Out', link: '/operations/2'},
            {name: 'Register Money Outcome', link: '/operations/3'},
            {name: 'Fix Stock Inventory', link: '/operations/4'},
            {name: 'Manage Price List', link: '/operations/5', restricted: true},
            {name: 'Edit User Information', link: '/operations/6', restricted: true},
            {name: 'Edit Employees Information', link: '/operations/7', restricted: true},
          ],
          openDetailPanel: false,
          openOptions: false,
          errorImgLoading: false,
          showNetworkStatus: false,
          isOnline: navigator.onLine || false,
          syncStatus: false
      }
  },
  mounted() {
    setInterval(() => {
        this.isOnline = navigator.onLine

        const syncData = localStorage.getItem('cache:wma:records')
        if (syncData) {
          this.syncStatus = JSON.parse(syncData).filter((el: any) => el.status == 'pending').length > 0
        } else {
          this.syncStatus = false
        }
    }, 1000)
  },
  computed: {
    isLogged() {
      return this.$store.state.jwt.length > 0 && this.$store.state.userDetail && this.$store.state.userDetail.hubs
    },
    profileUrl() {
      return ''.concat(config['host_ip'], config['host_port'], this.$store.state.userDetail?.user_img)
    },
    userDetail() {
      return this.$store.state.userDetail
    },
    logoutResult() {
      return this.$store.state.accessResult
    },
    isManager() {
      return this.$store.state.userDetail?.user_data?.groups.includes('Manager')
    },
    permittedOptions(): Array<any> {
      if (this.isManager) {
        return this.options
      } else {
        return this.options.filter( (el: any) => { return !el.restricted })
      }
    }
  },
  methods: {
    changeNetworkStatusFlag() {
      this.showNetworkStatus = !this.showNetworkStatus
    },
    isActive(url: string) {
      return this.$route.path.includes(url)
    },
    logout() {
      this.$store.commit("removeToken")
    },
    openDetail() {
      //console.log('click')
      this.openDetailPanel = document.getElementById('detail')?.classList.contains('show') || false
      const dropdowns = document.getElementsByClassName("dropdown-content");
      for (let i = 0; i < dropdowns.length; i++) {
          const openDropdown = dropdowns[i];
          if (openDropdown.classList.contains('show')) {
              openDropdown.classList.remove('show');
          }
      }
      if (!this.openDetailPanel) {
        document.getElementById('detail')?.classList.toggle("show");
      }
    },
    openSection(item: any) {
      if ( item.showOptions ) {
          this.openOptions = !this.openOptions
          return
      }

      this.openOptions = false
      this.$router.push(item.link)
    },
    openOperation(link: string) {
      this.openOptions = false
      this.$router.push(link)
    }
  },
  watch: {
    logoutResult: {
      handler: function(newVal) {
        if (newVal === '-1') {
          this.$router.push('/')
        }
      }
    }
  }

});
</script>

<style scoped>
.navbar {
  background-color: white;
  width: 100%;
  display: flex;
  align-items: center;
  height: 100%;
}

.menu {    
  margin-left: auto;
  align-items: center;
  display: flex;
}

/* Main content */
.main {
  margin-top: 3.3vh; /* Add a top margin to avoid content overlay */
}

.title {
  font-size: 20px;
  padding-left: 12px;
}

.active {
  text-decoration: underline;
  text-underline-offset: 5px;
  text-decoration-thickness: 2px;
}

.profile {
  border-radius: 50%;
  border: 1px solid #a9d8f7;
  width: 35px;
}

.icon-status {
  width: 4vh;
}

.text-style {
  text-align: start;
  font-size: 12px;
}

.temp-card {
  position: absolute;
  z-index: 100;
  width: 100%;
  display: inline-flex;
  min-height: 7vh;
  background: white;
  justify-content: center;
  box-shadow: 0px 5px 6px 0px #80808078;
}

.options-table {
  padding: 0.5vw 1.5vw 0.5vw 1.5vw;
  margin-top: auto;
  margin-bottom: auto;
}

.cell-border {
  border-left: 1px solid #a9d8f7;
  height: fit-content;
  text-align: center;
}

.cell-border:first-child {
  border-left: 0;
}

.badge {
  position: absolute;
  top: 0;
  left: 25px;
  background-color: #ff0000;
  width: 10px;
  height: 10px;
  text-align: center;
  border-radius: 10px;
  font-size: 13px;
  font-weight: bold;
}

.badge2 {
  font-size: 13px;
  font-weight: bold;
  margin-left: 10px;
}

@media screen and (max-width: 720px) {
  .hide-md {
    display: none;
  }

  .temp-card {
    display: block;
    text-align: center;
  }

  .cell-border {
    border-bottom: 1px solid #a9d8f7;
    border-left: 0;
    height: fit-content;
  }

  .cell-border:last-child {
    border-bottom: 0;
  }
}
</style>
