
import { defineComponent } from "@vue/runtime-core";
import config from '@/../public/assets/conf.json';

export default defineComponent({
    name: "User Detail",
    data() {
        return {
            errorImgLoading:false,
            syncData: 0
        }
    },
    mounted() {
        setInterval(() => {
            const syncData = localStorage.getItem('cache:wma:records')
            if (syncData) {
                this.syncData = JSON.parse(syncData).filter((el: any) => el.status == 'pending').length
            } else {
                this.syncData = 0
            }
        }, 1000)
    },
    computed: {
        profileUrl() {
            return ''.concat(config['host_ip'], config['host_port'], this.$store.state.userDetail?.user_img)
        },
        userDetail() {
            return this.$store.state.userDetail
        },
        logoutResult() {
            return this.$store.state.accessResult
        },
        ownRoles() {
            const roles = [] as any[]
            if(this.$store.state.userDetail?.user_data?.groups) {
                return this.$store.state.userDetail?.user_data?.groups.filter((el: any) => { return el != 'Subscriber'})
            }
            return roles
        },
        ownLocations() {
            const locations = [] as any[]
            if(this.$store.state.userDetail?.hubs) {
                return this.$store.state.userDetail?.hubs
            }
            return locations
        }
    },
    methods: {
        logout() {
            this.$store.commit("removeToken")
        },
        openSyncPage() {
            const dropdowns = document.getElementsByClassName("dropdown-content");
            for (let i = 0; i < dropdowns.length; i++) {
                const openDropdown = dropdowns[i];
                if (openDropdown.classList.contains('show')) {
                    openDropdown.classList.remove('show');
                }
            }
            this.$router.push('/synchronization')
        }
    },
    watch: {
        logoutResult: {
            handler: function(newVal) {
                if (newVal === '-1') {
                    this.$router.push('/')
                }
            }
        }
    }
})
