
import { defineComponent } from 'vue';
import config from '@/../public/assets/conf.json';
import UserDetail from './UserDetail.vue';
import { IonIcon } from '@ionic/vue';

export default defineComponent({
  name: 'Home',
  components: {
    IonIcon, UserDetail
  },
  data() {
      return {
          appName: config['app_name'],
          menuEntries: [
            {name: 'Your Hub', link: '/hub'},
            {name: 'Locations', link: '/locations'},
            {name: 'Operations', link: '/operations', showOptions: true},
            {name: 'Dashboard', link: '/dashboard'},
          ],
          options: [
            {name: 'Register Waste Delivery - In', link: '/operations/1'},
            {name: 'Register Waste Delivery - Out', link: '/operations/2'},
            {name: 'Register Money Outcome', link: '/operations/3'},
            {name: 'Fix Stock Inventory', link: '/operations/4'},
            {name: 'Manage Price List', link: '/operations/5', restricted: true},
            {name: 'Edit User Information', link: '/operations/6', restricted: true},
            {name: 'Edit Employees Information', link: '/operations/7', restricted: true},
          ],
          openDetailPanel: false,
          openOptions: false,
          errorImgLoading: false,
          openMenu: false,
          showNetworkStatus: false,
          isOnline: navigator.onLine || false,
          syncStatus: false
      }
  },
  mounted() {
    setInterval(() => {
        this.isOnline = navigator.onLine

        const syncData = localStorage.getItem('cache:wma:records')
        if (syncData) {
          this.syncStatus = JSON.parse(syncData).filter((el: any) => el.status == 'pending').length > 0
        } else {
          this.syncStatus = false
        }
    }, 1000)
  },
  computed: {
    isLogged() {
      return this.$store.state.jwt.length > 0 && this.$store.state.userDetail && this.$store.state.userDetail.hubs
    },
    profileUrl() {
      return ''.concat(config['host_ip'], config['host_port'], this.$store.state.userDetail?.user_img)
    },
    userDetail() {
      return this.$store.state.userDetail
    },
    logoutResult() {
      return this.$store.state.accessResult
    },
    isManager() {
      return this.$store.state.userDetail?.user_data?.groups.includes('Manager')
    },
    permittedOptions(): Array<any> {
      if (this.isManager) {
        return this.options
      } else {
        return this.options.filter( (el: any) => { return !el.restricted })
      }
    },
    getSyncStatus() {
      const syncData = localStorage.getItem('cache:wma:records')
      if (syncData) {
        return JSON.parse(syncData).filter((el: any) => el.status == 'pending').length > 0
      } 
      return false
    }
  },
  methods: {
    changeNetworkStatusFlag() {
      this.showNetworkStatus = !this.showNetworkStatus
    },
    openMenuList() {
      this.openMenu = !this.openMenu
    },
    isActive(url: string) {
      return this.$route.path.includes(url)
    },
    logout() {
      this.$store.commit("removeToken")
    },
    openDetail() {
      console.log('click')
      this.openDetailPanel = document.getElementById('mdetail')?.classList.contains('show') || false
      const dropdowns = document.getElementsByClassName("dropdown-content");
      console.log(dropdowns)
      for (let i = 0; i < dropdowns.length; i++) {
          const openDropdown = dropdowns[i];
          if (openDropdown.classList.contains('show')) {
              openDropdown.classList.remove('show');
          }
      }
      if (!this.openDetailPanel) {
        document.getElementById('mdetail')?.classList.toggle("show");
      }
    },
    openSection(item: any) {
      if ( item.showOptions ) {
          this.openOptions = !this.openOptions
          return
      }

      this.openOptions = false
      this.openMenuList()
      this.$router.push(item.link)
    },
    openOperation(link: string) {
      this.openOptions = false
      this.openMenuList()
      this.$router.push(link)
    }
  },
  watch: {
    logoutResult: {
      handler: function(newVal) {
        if (newVal === '-1') {
          this.$router.push('/')
        }
      }
    }
  }

});
