<template>
  <ion-app class="resizable-font">
    <ion-page>
      <ion-content scroll-y="false">  
        <div class="fixed-header">
          <Header></Header>
        </div>
        <div class="fixed-mheader">
          <Mobile-Header></Mobile-Header>
        </div>
        <div>
          <ion-router-outlet style="margin-top: 55px;" />
        </div>
      </ion-content>
    </ion-page>
  </ion-app>
</template>

<script lang="ts">
import { IonApp, IonRouterOutlet, IonPage, IonContent, /*IonGrid, IonRow, IonCol*/ } from '@ionic/vue';
import { defineComponent } from 'vue';
import Header from './views/components/Header.vue';
import MobileHeader from './views/components/MobileHeader.vue';

export default defineComponent({
    name: 'App',
    components: {
        IonApp, IonRouterOutlet, IonPage, IonContent, //IonGrid, IonCol, IonRow,
        Header, MobileHeader
    },
    mounted() {
        window.onclick = (event: any) => {
          if (!event.target.matches('.profile') && !event.target.matches('dropdown-content') ) {
              const dropdowns = document.getElementsByClassName("dropdown-content");
              for (let i = 0; i < dropdowns.length; i++) {
                  const openDropdown = dropdowns[i];
                  if (openDropdown.classList.contains('show')) {
                      openDropdown.classList.remove('show');
                  }
              }
          }
        }
    }
});
</script>

<style scoped>
.full-page {
  height: 100vh;
}

.content {
  background-color: #f0f6fe;
}

.fixed-header {    
  position: fixed;
  z-index: 10;
  width: 100%;
  height: 50px;
  top: 0;
}

.fixed-mheader {  
  display: none;
  position: fixed;
  z-index: 10;
  width: 100%;
  height: 50px;
  top: 0;
}


@media screen and (max-width: 720px) {
  .fixed-header {
    display: none;
  }

  .fixed-mheader {    
    display: block;
  }
}

.resizable-font {
  font-size: 1em;
}

@media screen and (max-width: 1200px) {
  .resizable-font {
    font-size: 1em;
  }
}

@media screen and (max-width: 992px) {
  .resizable-font {
    font-size: 1em;
  }
}

@media screen and (max-width: 768px) {
  .resizable-font {
    font-size: 1em;
  }
}

@media screen and (max-width: 576px) {
  .resizable-font {
    font-size: 0.7em;
  }
}
</style>
